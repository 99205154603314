import {
	Euler,
	EventDispatcher,
	Vector3
} from 'three';

const _euler = new Euler( 0, 0, 0, 'YXZ' );
const _vector = new Vector3();

const _changeEvent = { type: 'change' };
const _lockEvent = { type: 'lock' };
const _unlockEvent = { type: 'unlock' };

const _PI_2 = Math.PI / 2;

const shiftDistance = 0.4;

class PointerLockControls extends EventDispatcher {

	constructor( camera, domElement ) {

		super();

		this.camera = camera;
		this.domElement = domElement;
		this.enabled = true;

		this.isLocked = false;

		// Set to constrain the pitch of the camera
		// Range is 0 to Math.PI radians
		this.minPolarAngle = 0; // radians
		this.maxPolarAngle = Math.PI; // radians

		this.isShifted = false;
		this.updateReq = null;

		this.pointerSpeed = 1.0;

		this._onMouseMove = onMouseMove.bind( this );
		this._onPointerlockChange = onPointerlockChange.bind( this );
		this._onPointerlockError = onPointerlockError.bind( this );
		this._onKeyPressed = this.onKeyPressed.bind( this );
		this._onKeyReleased = this.onKeyReleased.bind( this );

		this.connect();

	}

	connect() {

		document.querySelector('canvas').addEventListener( 'pointermove', this._onMouseMove)
		// this.domElement.ownerDocument.addEventListener( 'pointermove', this._onMouseMove)
		// this.domElement.ownerDocument.addEventListener( 'mousemove', this._onMouseMove );
		this.domElement.ownerDocument.addEventListener( 'pointerlockchange', this._onPointerlockChange );
		this.domElement.ownerDocument.addEventListener( 'pointerlockerror', this._onPointerlockError );
		this.domElement.ownerDocument.addEventListener( 'keydown', this._onKeyPressed );
		this.domElement.ownerDocument.addEventListener( 'keyup', this._onKeyReleased );

	}

	disconnect() {

		this.domElement.ownerDocument.removeEventListener( 'mousemove', this._onMouseMove );
		this.domElement.ownerDocument.removeEventListener( 'pointerlockchange', this._onPointerlockChange );
		this.domElement.ownerDocument.removeEventListener( 'pointerlockerror', this._onPointerlockError );
		this.domElement.ownerDocument.removeEventListener( 'keydown', this._onKeyPressed );
		this.domElement.ownerDocument.removeEventListener( 'keyup', this._onKeyReleased );

	}

	dispose() {

		this.disconnect();

	}

	getObject() { // retaining this method for backward compatibility

		return this.camera;

	}

	getDirection( v ) {

		return v.set( 0, 0, - 1 ).applyQuaternion( this.camera.quaternion );

	}

	moveForward( distance ) {

		// move forward parallel to the xz-plane
		// assumes camera.up is y-up

		const camera = this.camera;

		_vector.setFromMatrixColumn( camera.matrix, 0 );

		_vector.crossVectors( camera.up, _vector );

		camera.position.addScaledVector( _vector, distance );

	}

	moveRight( distance ) {

		const camera = this.camera;

		_vector.setFromMatrixColumn( camera.matrix, 0 );

		camera.position.addScaledVector( _vector, distance );

	}

	onKeyPressed( event ) {
		if (!this.enabled) return;
		if (event.code === 'ShiftLeft') {
			this.shift();
		}
	}

	onKeyReleased( event ) {
		if (!this.enabled) return;
		if (event.code === 'ShiftLeft') {
			this.unshift();
		}
	}

	shift() {
		if (this.isShifted) return;
		// this.camera.position.y -= shiftDistance;
		this.targetY -= shiftDistance;
		this.isShifted = true;

		if (this.updateReq === null) {
			this.updateReq = requestAnimationFrame(this.update.bind(this));
		}
	}

	unshift() {
		if (!this.isShifted) return;
		// this.camera.position.y += shiftDistance;
		this.targetY += shiftDistance;
		this.isShifted = false;

		if (this.updateReq === null) {
			this.updateReq = requestAnimationFrame(this.update.bind(this));
		}
	}

	update() {
		this.updateReq = null;

		const diff = this.targetY - this.camera.position.y;
		if (Math.abs(diff) < 0.005) {
			this.camera.position.y = this.targetY;
		} else {
			this.camera.position.y += diff * 0.2;
			this.updateReq = requestAnimationFrame(this.update.bind(this));
		}
	}

	lock() {

		this.domElement.requestPointerLock?.();

	}

	unlock() {

		this.domElement.ownerDocument.exitPointerLock?.();

	}

}

// event listeners

function onMouseMove( event ) {
	// const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	if ((this.isLocked === false && !isMobile) || this.enabled === false ) {
		return;
	}

	const movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
	const movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

	const camera = this.camera;
	_euler.setFromQuaternion( camera.quaternion );

	_euler.y -= movementX * 0.002 * this.pointerSpeed;
	_euler.x -= movementY * 0.002 * this.pointerSpeed;

	_euler.x = Math.max( _PI_2 - this.maxPolarAngle, Math.min( _PI_2 - this.minPolarAngle, _euler.x ) );

	camera.quaternion.setFromEuler( _euler );

	this.dispatchEvent( _changeEvent );

}

function onPointerlockChange() {

	if ( this.domElement.ownerDocument.pointerLockElement === this.domElement ) {

		this.dispatchEvent( _lockEvent );

		this.isLocked = true;

	} else {

		this.dispatchEvent( _unlockEvent );

		this.isLocked = false;

	}

}

function onPointerlockError() {

	console.error( 'THREE.PointerLockControls: Unable to use Pointer Lock API' );

}

export { PointerLockControls };