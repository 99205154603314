export class LoadingProgressBar {

    constructor(container) {
        return

        this.idGen = 0;

        this.tasks = [];

        this.container = container || document.body;

        this.progressBarContainerOuter = document.createElement('div');
        this.progressBarContainerOuter.className = 'progressBarOuterContainer';
        this.progressBarContainerOuter.style.display = 'none';

        this.progressBarBox = document.createElement('div');
        this.progressBarBox.className = 'progressBarBox';

        this.progressBarTitle = document.createElement('div');
        this.progressBarTitle.className = 'progressBarTitle';
        this.progressBarTitle.innerHTML = 'Chargement en cours...';

        this.progressBarBackground = document.createElement('div');
        this.progressBarBackground.className = 'progressBarBackground';

        this.progressBar = document.createElement('div');
        this.progressBar.className = 'progressBar';

        this.progressBarBackground.appendChild(this.progressBar);
        this.progressBarBox.appendChild(this.progressBarTitle);
        this.progressBarBox.appendChild(this.progressBarBackground);
        this.progressBarContainerOuter.appendChild(this.progressBarBox);

        const style = document.createElement('style');
        style.innerHTML = `

            .progressBarOuterContainer {
                width: 100%;
                height: 100%;
                margin: 0;
                top: 0;
                left: 0;
                position: absolute;
                pointer-events: none;
            }

            .progressBarBox {
                z-index:99999;
                margin: 0;
                position: absolute;
                bottom: 50%;
                left: 50%;
                transform: translate(-50%, 0);
                width: 300px;
                pointer-events: auto;
            }   

            .progressBarTitle {
                font-family: Arial, sans-serif;
                font-size: 1.75em;
                white-space: nowrap;
                margin-bottom: 10px;
                color: #ffffff;
                text-align: center;
            }

            .progressBarBackground {
                width: 100%;
            }

            .progressBar {
                height: 6px;
                width: 0px;
                border-radius:2px;
                background-color: #1e88e5;
            }

        `;
        this.progressBarContainerOuter.appendChild(style);
        this.container.appendChild(this.progressBarContainerOuter);
    }

    show() {
        return
        this.progressBarContainerOuter.style.display = 'block';
    }

    hide() {
        return
        this.progressBarContainerOuter.style.display = 'none';
    }

    setProgress(progress) {
        return
        this.progressBar.style.width = progress + '%';
    }

    setContainer(container) {
        return
        if (this.container) {
            this.container.removeChild(this.progressBarContainerOuter);
        }
        if (container) {
            this.container = container;
            this.container.appendChild(this.progressBarContainerOuter);
            this.progressBarContainerOuter.style.zIndex = this.container.style.zIndex + 1;
        }
    }
}
