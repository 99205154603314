export class UncompressedSplatArray {

    static HarmonicComponentCount = {
        0: 0,
        1: 9,
        2: 24,
        3: 45
    }

    static OFFSET = {
        X: 0,
        Y: 1,
        Z: 2,
        SCALE0: 3,
        SCALE1: 4,
        SCALE2: 5,
        ROTATION0: 6,
        ROTATION1: 7,
        ROTATION2: 8,
        ROTATION3: 9,
        FDC0: 10,
        FDC1: 11,
        FDC2: 12,
        OPACITY: 13,
        
        // Spherical harmonics
        FR0: 14,
        FR1: 15,
        FR2: 16,
        FR3: 17,
        FR4: 18,
        FR5: 19,
        FR6: 20,
        FR7: 21,
        FR8: 22,
        FR9: 23,
        FR10: 24,
        FR11: 25,
        FR12: 26,
        FR13: 27,
        FR14: 28,
        FR15: 29,
        FR16: 30,
        FR17: 31,
        FR18: 32,
        FR19: 33,
        FR20: 34,
        FR21: 35,
        FR22: 36,
        FR23: 37,
        FR24: 38,
        FR25: 39,
        FR26: 40,
        FR27: 41,
        FR28: 42,
        FR29: 43,
        FR30: 44,
        FR31: 45,
        FR32: 46,
        FR33: 47,
        FR34: 48,
        FR35: 49,
        FR36: 50,
        FR37: 51,
        FR38: 52,
        FR39: 53,
        FR40: 54,
        FR41: 55,
        FR42: 56,
        FR43: 57,
        FR44: 58,
    };

    constructor() {
        this.splats = [];
        this.splatCount = 0;
    }

    static createSplat(shDegree = 0) {
        const base = [0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0]
        const harmonics = new Array(UncompressedSplatArray.HarmonicComponentCount[shDegree]).fill(0)
        return base.concat(harmonics);
    }

    addSplat(splat) {
        this.splats.push(splat);
        this.splatCount++;
    }

    getSplat(index) {
        return this.splats[index];
    }

    addDefaultSplat() {
        const newSplat = UncompressedSplatArray.createSplat();
        this.addSplat(newSplat);
        return newSplat;
    }

    addSplatFromComonents(x, y, z, scale0, scale1, scale2, rot0, rot1, rot2, rot3, r, g, b, opacity, ...harmonics) {
        // if (harmonics.length != 45) throw new Error("Invalid number of harmonics: " + harmonics.length);
        if (harmonics.some(e => isNaN(parseFloat(e)))) {
            // console.log(harmonics)
            throw new Error(`Invalid harmonic value`)
        }
        const newSplat = [x, y, z, scale0, scale1, scale2, rot0, rot1, rot2, rot3, r, g, b, opacity, ...harmonics];
        this.addSplat(newSplat);
        return newSplat;
    }

    addSplatFromArray(src, srcIndex) {
        const srcSplat = src.splats[srcIndex];
        this.addSplatFromComonents(srcSplat[0], srcSplat[1], srcSplat[2], srcSplat[3], srcSplat[4], srcSplat[5],
                                   srcSplat[6], srcSplat[7], srcSplat[8], srcSplat[9],
                                   srcSplat[10], srcSplat[11], srcSplat[12], srcSplat[13], ...srcSplat.slice(14));
    }
}
